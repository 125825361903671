import React from 'react'
import logo from '../images/Untitled-1.svg';
import useWebAnimations from '@wellyshen/use-web-animations';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t, i18n } = useTranslation();
    const { ref } = useWebAnimations({
        keyframes: [
            { transform: "translateY(-10%)" },
            { transform: "translateY(0%)" },
        ],
        timing: {
            direction: "alternate-reverse",
            easing: "ease-in-out",
            duration: 2000,
            playbackRate: 1,
            iterations: Infinity
        },
    });

    return (
        <div>
            <div style={{ marginBottom: '-22.25%' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <defs>
                        <linearGradient id="waveGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stop-color="#ffffff" />
                            <stop offset="100%" stop-color="#6459fd" />
                        </linearGradient>
                    </defs>
                    <path fill="url(#waveGradient)" fill-opacity="1" d="M0,224L60,192C120,160,240,96,360,112C480,128,600,224,720,224C840,224,960,128,1080,85.3C1200,43,1320,53,1380,58.7L1440,64L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
                </svg>
            </div>
            <footer className="container-fluid customFooter">
                <div className="container">
                    <div className="row">
                        <div className="col-auto">
                            <a href="#">
                                <img
                                    width="100px"
                                    src={logo}
                                    className="d-inline-block align-top footer-logo"
                                    alt="hurryapp"
                                />
                            </a>
                        </div>
                        
                    </div>

                    <div className="row justify-content-md-center">
                        <div className="col-sm-4">
                            <p className="footer-content">{new Date().getFullYear()} {t('rights')}</p>
                            <p className="footer-content">Powered By QIT</p>
                        </div>
                        <div className="col-sm">
                            <a><p className="footer-content">{t('android')}</p></a>
                        </div>
                        <div className="col-sm">
                            <p className="footer-content">{t('ios')}</p>
                        </div>
                        <div className="col-sm">
                            <span className="social-icon">
                                <i className="nav-item nav-link fab fa-instagram float-right"></i>
                            </span>
                            <span className="social-icon">
                                <i className="nav-item nav-link fab fa-facebook-f float-right"></i>
                            </span>
                            <span className="social-icon">
                                <i className="nav-item nav-link fab fa-twitter float-right"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
