import React from 'react'
import Kenya from '../images/008-kenya.png'
import Pakistan from '../images/001-pakistan.png'
import Egypt from '../images/010-egypt-2.png'
import { useTranslation } from 'react-i18next'

const Locations = () => {
    const { t, i18n } = useTranslation();
    return (
        <div id="AboutUs" className="container text-center">
            <br /><br /><br />
            <h1 className="section-heading">{t('about_liblab')}</h1>
            <p className="section-p">
                {t('about_liblab_content')}
            </p>
            <br /><br />
            <br /><br />
            <br /><br />
            <br /><br />
            <br /><br />
            <br /><br />
        </div>
    )
}

export default Locations
