import React, { useEffect, useRef, useState, useCallback } from 'react'
import logo from '../images/Untitled-2.svg';
import { Menu, Transition } from "@headlessui/react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export const allLangs = [
    {
        name: 'English',
        code: 'en',
        icon: 'flagpack:gb-nir',
    },
    {
        name: 'Arabic',
        code: 'ar',
        icon: 'flagpack:ae',
    },
];

const Nav = () => {
    const { t, i18n } = useTranslation();
    const currentLang = allLangs.find((lang) => lang.value === i18n.language) || allLangs[0];

    // Debugging: Log the i18n object on mount
    useEffect(() => {
        console.log('i18n object on mount:', i18n);
    }, [i18n]);
    let navRef = useRef(null);
    const [isScroll, setIsScroll] = useState(false);

    const handleChangeLang = useCallback(
        (option) => {
            console.log('Changing language to:', option.code);
            console.log('i18n object in handleChangeLang:', i18n);

            if (i18n && typeof i18n.changeLanguage === 'function') {
                i18n.changeLanguage(option.code); // Change the language // Close the popover
            } else {
                console.error('i18n.changeLanguage is not a function');
            }
        },
        [i18n]
    );

    useEffect(() => {
        const onScroll = e => {
            if (e.target.documentElement.scrollTop > navRef.current.clientHeight) { setIsScroll(true); }
            else { setIsScroll(false); }
        };
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, []);


    return (
        <nav ref={navRef} className={`navbar navbar-expand-lg navbar-dark fixed-top navbar-fixed-top ${isScroll ? "scrolled" : ""}`}>
            <a className="navbar-brand" href="#">
                <img
                    src={logo}
                    height="25"
                    className="d-inline-block align-top"
                    alt="logo"
                />
            </a>

            <div className="d-flex flex-row order-2 order-lg-3">

                <ul className="navbar-nav flex-row">
                    <li className="nav-item dropdown d-flex align-items-center">
                        <Menu as="div" className="nav-link p-0 d-flex align-items-center position-relative">
                            <Menu.Button
                                className="nav-link d-flex align-items-center"
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    padding: 0,
                                    color: 'white',
                                    cursor: 'pointer',
                                    fontSize: '1rem',
                                }}
                            >
                                <GlobeAltIcon style={{ height: '20px', width: '20px', marginRight: '6px', color: 'white' }} />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="dropdown-menu show"
                                    style={{

                                        position: 'absolute',
                                        top: '100%',
                                        right: '100%',
                                        marginRight: '0.25rem',
                                        zIndex: 10,
                                        minWidth: '190px',
                                        marginTop: '0.25rem',
                                        borderRadius: '0.375rem',
                                        backgroundColor: '#fff',
                                        boxShadow: '0 0.5rem 1rem rgba(0,0,0,0.15)',
                                        padding: '0.25rem 0',
                                    }}
                                >
                                    {allLangs.map((lng) => (
                                        <Menu.Item key={lng.code}>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => handleChangeLang(lng)}
                                                    disabled={i18n.language === lng.code}
                                                    className="dropdown-item d-flex align-items-center"
                                                    style={{
                                                        fontSize: '0.9rem',
                                                        color: '#212529',
                                                        backgroundColor: active ? '#f8f9fa' : 'transparent',
                                                        cursor: i18n.language === lng.code ? 'not-allowed' : 'pointer',
                                                    }}
                                                >
                                                    <span className={`fi fi-${lng.icon}`} style={{ marginRight: '0.5rem' }}></span>
                                                    {lng.name}
                                                </button>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link px-2" href="#"><span className="fab fa-facebook"></span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link px-2" href="#"><span className="fab fa-twitter"></span></a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link px-2" href="#"><span className="fab fa-instagram"></span></a>
                    </li>

                </ul>


            </div>




            <div
                className=" navbar-collapse order-3 order-lg-2"
                id="navbarNavDropdown"
            >



                <ul className="navbar-nav ml-auto d-none d-lg-flex">

                    <li className="nav-item">
                        <a className="nav-link" href="#AboutUs">{t('about_us')}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#GetAppNow">{t('get_app')}</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Nav
